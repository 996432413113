@import '../../styles/_variables.sass';

// .ReactModalPortal
// 	height: 100vh
// 	&:empty
// 		height: 0

.ReactModalPortal {
	transform: translate3d(0, 0, 1); }

.general-modal {
	&__overlay {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		text-align: center;
		@media #{$tablet_desktop} {
			background-color: rgba($blackColor, .27); }
		@media #{$phone} {
			background-color: rgba($blackColor, .8); }
		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			width: 0; } }

	&__body {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		overflow: visible;
		outline: none;
		background-color: $whiteColor;
		border-radius: $border-radius-panel;
		//padding: 40px
		margin: 50px 0;
		text-align: left;
		@media #{$phone} {
			width: 96%;
			max-width: 420px; }
		&_public-auth {
			width: 420px;
			padding: 0; }
		&_add-employer-modal {
			@media #{$tablet_desktop} {
				width: 420px; } }
		&_account-buy {
			background-color: white;
			@media #{$tablet_desktop} {
				width: 500px;
				padding: 40px 32px; }
			@media #{$phone} {
				padding: 40px 20px; }
			.account-modal-buy {
				img.main {
					display: block;
					@media #{$tablet_desktop} {
						margin: -112px auto 0;
						padding-right: 26px; }
					@media #{$phone} {
						width: 65px;
						margin: 0 auto; } } } }
		&_modal-create-test,
		&_modal-info {
			padding: 0 32px 40px 32px;
			background: $gradient-default;
			@media #{$tablet_desktop} {
				width: 420px; }
			.modal-create-test,
			.modal-info,
			.account-modal-buy {
				img {
					display: block;
					margin: -72px auto 0; }
				&__text {
					margin: 15px auto;
					font-family: $fontGeneral;
					font-size: $font-size-h3;
					font-weight: 500;
					text-align: center;
					color: $whiteColor; }
				&__success {
					display: flex;
					flex-direction: column;
					align-items: center; }
				&__button-bottom {
					margin-top: 24px; } } }
		&_modal-skills {
			padding: 40px 32px;
			width: 820px; } }
	&__close {
		height: auto;
		position: absolute;
		color: $whiteColor;
		opacity: .5;
		font-size: 18px;
		cursor: pointer;
		@media #{$tablet_desktop} {
			top: 0;
			right: -30px; }
		@media #{$phone} {
			bottom: 100%;
			right: 0;
			margin-bottom: 10px; } } }
