@import './variables';
@import './mixins';

html {
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
 }	// min-height: 100%

* {
	&,
	&:before,
	&:after {
		box-sizing: border-box; } }

body {
	margin: 0;
	padding: 0;
	// height: 100%
	font-family: $fontDefault;
	font-size: $font-size-main;
	line-height: 1.2;

	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	// font varient
	font-variant-ligatures: none;
	-webkit-font-variant-ligatures: none;
	// smoothing
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased; }

.trn-disabled {
	transition: none !important; }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

ul {
	margin: 0;
	padding: 0; }

input {
	font: inherit;
	color: inherit;
	padding: 0; }

#root {
	display: flex;
	flex-direction: column;
	position: relative; }

.public-main {
	z-index: 2;
	display: flex;
	flex-grow: 1;
	padding-bottom: $header-height;
	padding-top: 30px; }

.content {
	transition: max-width .2s ease-out;
	padding-bottom: 65px;
	&-wrapper {
		height: 100%;
		position: relative;
		z-index: 2;
		@media #{$tablet_desktop} {
			display: flex; }
		@media #{$phone} {
			padding-top: 80px; }
		.menu-collapsed {
			~ .content {
				@media #{$tablet_desktop} {
					max-width: calc(100% - #{$menuCollapsedWidth}); } } }
		.menu-expanded {
			~ .content {
				@media #{$md} {
					max-width: calc(100% - #{$menuExpandedWidth}); } } } }
	& {
		flex-grow: 1;
		flex-shrink: 1; } }

.container {
	margin: 0 auto;
	@media #{$desktop} {
		padding: 0 $gutter-main; }
	@media #{$phone_tablet} {
		padding: 0 $gutter-mobile; }
	//@media #{$md}
	//	width: $mdWidth - 20px
	//@media #{$sm}
	//	width: 960px
	// @media #{$tablet}
 }	//	width: 760px

.account-page-bg {
	position: fixed;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: url('./static/i/bg-pattern.png') repeat 0 0;
	&:before,
	&:after {
		content: "";
		position: absolute; }
	&:before {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, transparent 0%, rgba(#000,.5) 100%); }
	&:after {
		@media #{$tablet_desktop} {
			right: 0;
			bottom: 0;
			background: url('./static/i/dart-bg.png') no-repeat 0 0;
			background-size: cover;
			width: 404px;
			height: 372px; } } }

/* Main classes for fonts from style guide */

.m-h1 {
	font-size: $font-size-h1;
	font-family: $fontGeneral;
	font-weight: bold;
	color: $blackColor; }

.m-h2 {
	font-size: $font-size-h2;
	font-family: $fontGeneral;
	font-weight: 300;
	color: $blackColor; }

.m21 {
	font-size: $font-size-h3;
	font-family: $fontGeneral;
	font-weight: 500;
	color: $blackColor; }

.r18 {
	font-size: $font-size-lg; }

.r16 {
	font-size: $font-size-md; }

.r12 {
	font-size: $font-size-sm; }

.l16 {
	font-size: $font-size-sm;
	font-weight: 300; }

.l14 {
	font-size: $font-size-xs; }

.public-auth {
	&__wrapper {
		border-radius: $border-radius-panel;
		overflow: hidden; }
	&__body {
		&_forgot {
			background: linear-gradient(180deg, $mainColor 0%, $mainColorDark 100%);
			box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); } } }

.public-forgot {
	&__title {
		font-size: $font-size-h3;
		color: $whiteColor; }
	&__instruction {
		font-size: $font-size-xs;
		color: $whiteColor; } }

.public-confirmation {
	&__text {
		color: $whiteColor; } }

.create-new-password {
	background: linear-gradient(180deg, #B2CE55 0%, #9BBC2B 100%);
	box-shadow: 0 8px 16px rgba(2, 3, 3, 0.1); }

.react-datepicker {
	&__input {
		&-container {
			input {
				height: 35px;
				border: 0;
				border-bottom: 2px solid $beigeColor;
				background-color: inherit; } } }
	&-popper {
		z-index: 12 !important; }
	&__day {
		&--selected {
			background-color: $beigeColor !important; }
		&--keyboard {
			&-selected {
				background-color: $beigeColor !important; } } } }

.tabs {
	display: flex;
	justify-content: center;
	button {
		@include buttonReset; }
	&__button {
		font-family: $fontGeneral;
		font-weight: 500;
		color: $whiteColor;
		text-decoration: none;
		height: 50px;
		@media #{$desktop} {
			margin: 0 30px;
			font-size: $font-size-h3; }
		@media #{$tablet} {
			margin: 0 20px;
			font-size: $font-size-lg; }
		@media #{$phone} {
			margin: 0 15px;
			font-size: $font-size-md; }
		&.active {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: -5px;
				right: -5px;
				height: 3px;
				background: $mainColor; } } } }


.table-actions {
	button + button {
		margin-left: 15px; }
	button.icon-trash {
		color: #F05656; } }
