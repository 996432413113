@import './variables.sass';

input[type=checkbox],
input[type=radio] {
	display: block;
	position: absolute;
	top: -8px;
	z-index: 10;
	opacity: 0;
	height: 16px;
	width: 16px;
	margin: 0;
	cursor: pointer;
	&:not([disabled]) {
		~ span {
			cursor: pointer; } } }

.static-form-label {
	font-size: $form-label-size;
	&-grey {
		color: $beigeColorGrey; } }

.form-control-default {
	position: relative;
	input {
		position: relative;
		z-index: 11;
		width: 100%;
		background-color: transparent;
		height: 35px;
		font-size: $form-font-size;
		outline: none;
		border: none;
		border-radius: 0;
		-webkit-appearance: none;
		border-bottom: 2px solid $form-default-border-color;
		transition: border-bottom .4s;
		~ span {
			position: absolute;
			font-size: $form-font-size;
			top: 50%;
			left: 0;
			margin-top: -10px;
			transition: all .4s; }
		&.brown-border {
			color: $whiteColor;
			border-bottom: 2px solid $beigeColor;
			~ span {
				color: $whiteColor;
				z-index: 10; }
			&:focus ~ span,
			&:valid ~ span,
			&[readOnly] ~ span {
				top: -3px;
				font-size: $form-label-size;
				color: $beigeColorLight; } }
		&.grey-border {
			color: $blackColorDark;
			border-bottom: 2px solid $mainColor;
			&[disabled] {
				border-bottom: 2px solid rgba($greyColor, .4); }
			~ span {
				color: $blackColorDark; }
			&:focus ~ span,
			&:valid ~ span {
				top: -3px;
				font-size: $form-label-size;
				color: $beigeColorGrey; } }
		&.error-border {
			border-bottom: 2px solid $redColor;
			~ span {
				color: $redColor !important; } }
		&::placeholder {
			color: $blackColor; }
		&:focus ~ span,
		&:valid ~ span,
		&[readOnly] ~ span {
			top: -3px;
			font-size: $form-label-size; } }
	input[readOnly] {
		opacity: 0.5;
		cursor: default;
		// color: $form-default-readonly-color
 }		// background: $form-disabled-bg-color
	&__checkbox {
		position: relative;
		display: flex;
		min-height: 1px;
		&.opacity {
			opacity: 0; }
		input:checked {
			+ span {
				&:before {
					background-color: $form-default-border-color; }
				&:after {
					opacity: 1;
					transform: rotate(0); } } }
		span {
			display: flex;
			position: absolute;
			top: -8px;
			padding-left: 26px;
			height: auto;
			font-size: $font-size-xs;
			line-height: 1.3;
			text-align: left;
			color: $blackColorDark;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				width: 16px;
				height: 16px;
				left: 0;
				top: 0;
				margin: auto;
				border: 2px solid $form-default-border-color;
				border-radius: 2px;
				transition: background-color .2s ease-out; }
			&:after {
				content: '\E915';
				font-family: 'icons';
				color: #fff;
				position: absolute;
				top: 1px;
				left: 2px;
				font-size: 12px;
				opacity: 0;
				transform: rotate(-45deg);
				transition: all .2s ease-out; } } }
	&__radio {
		position: relative;
		input:checked {
			+ span:after {
				opacity: 1;
				transform: scale(1); } }
		input[disabled] + span {
			opacity: .5; }
		span {
			display: flex;
			position: relative;
			padding-left: 24px;
			color: $blackColorDark;
			font-size: $form-font-size;
			text-align: left;
			&:before {
				content: '';
				position: absolute;
				width: 16px;
				height: 16px;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				border: 2px solid $form-default-border-color;
				border-radius: 50%; }
			&:after {
				content: '';
				position: absolute;
				width: 8px;
				height: 8px;
				left: 4px;
				top: 0;
				bottom: 0;
				margin: auto;
				background: $form-default-border-color;
				border-radius: 50%;
				opacity: 0;
				transform: scale(3.4);
				transition: all .2s ease-out; } }
		&.brown-border {
			span {
				color: $whiteColor;
				&:before {
					border: 2px solid $form-accent-border-color; } } } } }

.form-control-error {
	position: absolute;
	font-size: $font-size-xxs;
	text-align: left;
	color: $redColor; }

.birth-date {
	&__form {
		&-input {
			&__birthday {
				input[readOnly] {
					color: $form-default-readonly-color; } } } } }
