@font-face {
	font-family: 'icons';
	src: url(./static/f/icons/icons.ttf) format('truetype'), url(./static/f/icons/icons.woff) format('woff'), url(./static/f/icons/icons.svg) format('svg');
	font-weight: normal;
	font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: 'icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.icon-shape:before {
  content: "\e927"; }
.icon-eye:before {
	content: "\e900"; }
.icon-eye-close:before {
	content: "\e901"; }
.icon-login:before {
	content: "\e902"; }
.icon-logout:before {
	content: "\e903"; }
.icon-home:before {
	content: "\e904"; }
.icon-settings:before {
	content: "\e905"; }
.icon-settings:before {
	content: "\e905"; }
.icon-magic-stick:before {
	content: "\e906"; }
.icon-structure:before {
	content: "\e907"; }
.icon-user:before {
	content: "\e908"; }
.icon-group:before {
	content: "\e909"; }
.icon-piechart:before {
	content: "\e90a"; }
.icon-key:before {
	content: "\e90b"; }
.icon-info:before {
	content: "\e90c"; }
.icon-question:before {
	content: "\e90d"; }
.icon-chat:before {
	content: "\e90e"; }
.icon-arrow-small-left:before {
	content: "\e90f"; }
.icon-arrow-small-right:before {
	content: "\e910"; }
.icon-arrow-small-bottom:before {
	content: "\e913"; }
.icon-arrow-small-top:before {
	content: "\e914"; }
.icon-photo:before {
	content: "\e911"; }
.icon-key-full:before {
	content: "\e912"; }
.icon-ok:before {
	content: "\e915"; }
.icon-cross:before {
	content: "\e916"; }
.icon-trash:before {
	content: "\e917"; }
.icon-edit:before {
	content: "\e918"; }
.icon-download:before {
	content: "\e919"; }
.icon-download-cloud:before {
	content: "\e91a"; }
.icon-download-cloud-2:before {
	content: "\e91b"; }
.icon-envelope:before {
	content: "\e91c"; }
.icon-list:before {
	content: "\e91d"; }
.icon-schema:before {
	content: "\e91e"; }
.icon-folders:before {
	content: "\e91f"; }
.icon-copy:before {
	content: "\e920"; }
.icon-loading-circles:before {
	content: "\e921"; }
.icon-user-2:before {
	content: "\e922"; }
.icon-gamepad:before {
	content: "\e923"; }
.icon-share:before {
    content: "\e924"; }
.icon-restore:before {
	content: "\e925"; }
.icon-flag:before {
	content: "\e926"; }
